<template>
	<div>
		<div class="title mb-5">
			<h4 class="text-center">Create Time Entry</h4>
		</div>

		<div class="form mb-5">
			<b-form-group
				label-cols="10"
				label-cols-sm="2"
				label="Project"
				label-for="project"
			>
				<b-form-select
					id="project"
					v-model="project"
					:options="projectOptions"
				>
					<template #first>
						<b-form-select-option value="null">-- Select a project --</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>

			<b-form-group
				label-cols="10"
				label-cols-sm="2"
				label="Date"
				label-for="date"
			>
				<b-form-datepicker
					id="date"
					v-model="date"
					class="mb-2"
					start-weekday="1"
				/>
			</b-form-group>

			<b-form-group
				label-cols="10"
				label-cols-sm="2"
				label="Hours"
				label-for="durationHH"
			>
				<b-form-input
					id="durationHH"
					class="duration-input"
					step="1"
					min="0"
					v-model="durationHH"
					type="number"
					placeholder="H"
					:class="{ 'is-invalid': ($v.durationTotalMinutes.$error || $v.durationHH.$error) }"
					@input="$v.durationTotalMinutes.$touch"
					@blur="$v.durationTotalMinutes.$touch"
				/>
				<div class="invalid-feedback" v-if="!$v.durationHH.integer && $v.durationHH.$dirty">Hour value must be a whole number.</div>
			</b-form-group>

			<b-form-group
				label-cols="10"
				label-cols-sm="2"
				label="Minutes"
				label-for="durationMM"
			>
				<b-form-input
					id="durationMM"
					class="duration-input"
					step="5"
					min="0"
					max="55"
					v-model="durationMM"
					type="number"
					placeholder="MM"
					:class="{ 'is-invalid': ($v.durationTotalMinutes.$error || $v.durationMM.$error) }"
					@input="$v.durationTotalMinutes.$touch"
					@blur="$v.durationTotalMinutes.$touch"
				/>
				<div class="invalid-feedback" v-if="!$v.durationTotalMinutes.minValue && $v.durationTotalMinutes.$dirty">Duration must be a minimum of 5 minutes.</div>
				<div class="invalid-feedback" v-if="!$v.durationMM.maxValue && $v.durationMM.$dirty">Minutes must be less than 60.</div>
				<div class="invalid-feedback" v-if="!$v.durationMM.isIncrementOfFive && $v.durationMM.$dirty">Minutes must in increments of 5.</div>
			</b-form-group>

			<b-form-group
				label-cols="10"
				label-cols-sm="2"
				label="Tags"
				label-for="tags"
			>
				<vue-tags-input
					id="tags"
					v-model="tag"
					:tags="tags"
					:validation="validation"
					:autocomplete-items="filteredItems"
					:autocomplete-min-length="0"
					:separators="separators"
					@tags-changed="newTags => tags = newTags"
				/>
				<p>
					<small>
						Tags may contain alphanumeric characters, hyphens and underscores.
					</small>
				</p>
			</b-form-group>
		</div>

		<div class="text-center">
			<b-button
				type="submit"
				variant="primary"
				class="w-md ml-1 mr-1"
				:disabled="isSaving"
				@click="createTimeEntry"
				v-shortkey.once="['ctrl', 'enter']"
				@shortkey="createTimeEntry"
			>
				OK
			</b-button>

			<b-button
				v-if="showCancel"
				type="submit"
				variant="secondary"
				class="w-md ml-1 mr-1"
				@click="$emit('cancelCreateTimeEntry')"
			>
				Cancel
			</b-button>
		</div>
		
	</div>
</template>

<script>
import UserProjectsService from '@/services/user-projects';
import errorPopup from '@/utils/error-popup';
import { apiPost } from "@/utils/api";
import { required, minValue, maxValue, integer } from 'vuelidate/lib/validators';
import VueTagsInput from '@johmun/vue-tags-input';
import TagsService from '@/services/user-tags';

export default {
	name: 'CreateTimeEntry',

	components: {
		VueTagsInput,
	},

	props: {
		showCancel: {
			type: Boolean,
			default: false,
			required: false,
		},
	},

	data() {
		return {
			project: null,
			userProjects: null,
			date: this.$moment().format('YYYY-MM-DD'),
			durationHH: null,
			durationMM: null,
			tag: '',
			tags: [],
			autocompleteItems: [],
			separators: [' ', ','],
			validation: [
				{
					classes: 'no-illegal-characters',
					rule: /^[a-zA-Z0-9-_]+$/,
					disableAdd: true,
				}
			],
			isSaving: false,
		}
	},

	computed: {
		projectOptions() {
			const options = [];
			if (this.userProjects) {
				this.userProjects.forEach((userProject) => {
					options.push({
						value: userProject.id,
						text: userProject.project_name,
					})
				});
			}
			return options;
		},
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		filteredItems() {
			return this.autocompleteItems.filter(i => {
				return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
			});
		},
		durationTotalMinutes() {
			return (
				Number(this.durationHH) * 60 + Number(this.durationMM)
			);
		}
	},

	methods: {
		createTimeEntry() {
			this.$v.$touch()
			if (this.$v.$invalid || this.isSaving) {
				return;
			}

			this.isSaving = true;

			let tags = this.tags.map(tag => tag.text);
			const params = {
				date: this.date,
				duration: this.durationTotalMinutes,
				tags,
				project_id: this.project,
			}

			apiPost(`users/${this.userInfo.id}/time-entries`, params)
				.then(() => this.$emit('timeEntryCreated'))
				.catch(error => {
					errorPopup(error);
					this.isSaving = false;
				});
		},
	},

	validations: {
		durationTotalMinutes: {
			required,
			minValue: minValue(5),
		},
		durationHH: {
			integer,
		},
		durationMM: {
			maxValue: maxValue(55),
			isIncrementOfFive: (value) => value % 5 === 0,
		}
	},

	async mounted() {
		const projects = await UserProjectsService.listAll(this.userInfo.id);
		this.userProjects = projects.filter((project) => !project.archived_at)
		this.autocompleteItems = await TagsService.listAll(this.userInfo.id);
	},
}
</script>

<style lang="scss">
.duration-inputs {
	display: flex;
}

.duration-input {
	max-width: 100px;
	margin-right: 10px;
}

.vue-tags-input {
	.ti-tag {
		cursor: default;
		border-radius: 0.25rem;
	}

	.ti-tag-center, .ti-new-tag-input, .ti-item {
		font-family: "Roboto", sans-serif;
	}

	.ti-tag:before {
		content: none;
	}

	.ti-autocomplete ul {
		max-height: 250px;
		overflow-y: scroll;
	}
}
</style>